import React, { useEffect, useState } from 'react';
import { Cascader, Divider, Button, Skeleton, Space } from 'antd';

import ServiceUsers from '../../services/users';
import ServiceTasks from '../../services/tickets';
import ServiceBuilds from '../../services/builds';
import ServiceWorklogs from '../../services/worklogs';

const FilterComponent = ({ onFilter }) => {
    const [options, setOptions] = useState([]);
    // const [selectedValues, setSelectedValues] = useState([]);
    const [users, setUsers] = useState([]);
    const [builds, setBuilds] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [userResponse, buildResponse, accountResponse, taskResponse] = await Promise.all([
                ServiceUsers.getAllUsers(),
                ServiceBuilds.getBuilds(),
                ServiceBuilds.getAccountsWithBuilds(),
                ServiceTasks.getAll()
            ]);

            const userData = await Promise.all(userResponse.data.map(async (item) => {
                const user = {
                    id: item.id,
                    email: item.email,
                    display_name: item.display_name,
                    active: item.active,
                    builds: item.builds,
                    worklogs: []
                };

                if (item.worklogs && item.worklogs.length > 0) {
                    const worklogPromises = item.worklogs.map(async (worklog) => {
                        const response = await ServiceTasks.getTask(worklog.task_key);
                        worklog.task = response?.data?.task || null;
                        return worklog;
                    });

                    user.worklogs = await Promise.all(worklogPromises);
                }

                return user;
            }));



            const buildData = buildResponse.data;
            const accountData = accountResponse.data;
            const taskData = taskResponse.data;

            setUsers(userData);
            setBuilds(buildData);
            setAccounts(accountData);
            setTasks(taskData);

            console.log('taskData', taskData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const userOptions = {
            label: 'Users',
            value: 'users',
            children: users.map(user => ({
                label: user.display_name,
                value: user.id,
            })),
        };

        const buildOptions = {
            label: 'Builds',
            value: 'builds',
            children: builds.map(build => ({
                label: build.sow_name,
                value: build.id,
            })),
        };

        const accountOptions = {
            label: 'Accounts',
            value: 'accounts',
            children: accounts.map(account => ({
                label: account.account_name,
                value: account.id,
            })),
        };

        const taskOptions = {
            label: 'Tasks',
            value: 'tasks',
            children: [
                {
                    label: 'Summary',
                    value: 'summary',
                    children: [...new Set(tasks.map(task => task.summary))].map(summary => ({
                        label: summary,
                        value: summary
                    })),
                },
                {
                    label: 'Status',
                    value: 'status',
                    children: [...new Set(tasks.map(task => task.status))].map(status => ({
                        label: status,
                        value: status
                    })),
                },
                {
                    label: 'Priority',
                    value: 'priority',
                    children: [...new Set(tasks.map(task => task.priority))].map(priority => ({
                        label: priority,
                        value: priority
                    })),
                },
                {
                    label: 'Billing',
                    value: 'billing',
                    children: [...new Set(tasks.map(task => task.billing))].map(billing => ({
                        label: billing,
                        value: billing
                    })),
                },
            ]
        };

        setOptions([userOptions, buildOptions, accountOptions, taskOptions]);
    }, [users, builds, accounts, tasks]);

    const onChange = (value) => {
        console.log('value', value);
        // setSelectedValues(value);
        onFilter(value);
    };

    // const handleFilter = () => {
    //     if (onFilter) {
    //         onFilter(selectedValues);
    //     }
    // };

    const dropdownRender = (menus) => (
        <div>

            <div style={{ minWidth: '300px' }}>
                {menus}
                <Divider style={{ margin: 0 }} />
                <div style={{ padding: '8px 16px' }}>
                    <span>Filters will apply <span style={{ fontWeight: '700' }}>dynamically</span> on click.</span>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Cascader
                options={options}
                style={{ width: '300px' }}
                dropdownRender={dropdownRender}
                onChange={onChange}
                multiple
                placeholder="Filters"
                maxTagCount="responsive"
                showCheckedStrategy={Cascader.SHOW_CHILD}
                showSearch={true}
            />
            {/* <Button onClick={handleFilter} style={{ marginTop: '16px' }}>
                Filter
            </Button> */}
        </div>
    );
};

export default FilterComponent;