import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Table, DatePicker, Layout, Avatar, Select, Tag, Button, ConfigProvider, Modal, Space } from 'antd';
import moment from 'moment';
import './Tempo.css';
import dayjs from 'dayjs';
import {
    BulbTwoTone,
    CalendarTwoTone,
    DownloadOutlined,
    DownOutlined
} from "@ant-design/icons";
// Services
import ServiceUsers from '../../services/users';
import ServiceTasks from '../../services/tickets';
import ServiceBuilds from '../../services/builds';
import ServiceWorklogs from '../../services/worklogs';

import styled from 'styled-components';
import TempoModal from '../TempoModal/TempoModal';
import worklogs from '../../services/worklogs';
import { OpacityRounded } from '@mui/icons-material';

import FilterComponent from '../FilterComponent/FilterComponent';

const { RangePicker } = DatePicker;
const { Content } = Layout;

const currentMonthStart = dayjs().startOf('month');
const currentMonthEnd = dayjs().endOf('month');

const currentWeekStart = dayjs().startOf('week').add(1, 'day');
const currentWeekEnd = dayjs().endOf('week').add(1, 'day');

const rangePresets = [
    {
        label: <span style={{ fontWeight: '700' }}>Current Month</span>,
        value: [currentMonthStart, currentMonthEnd],
    },
    {
        label: <span style={{ fontWeight: '700' }}>Current Week</span>,
        value: [currentWeekStart, currentWeekEnd],
    },
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];

const getCurrentMonthRange = () => {
    const startOfMonth = dayjs().startOf('month').toDate();
    const endOfMonth = dayjs().endOf('month').toDate();
    return [startOfMonth, endOfMonth];
};

const StyledTable = styled((props) => <Table {...props} />)`
    tbody > tr > td.basic-cell:hover,
    tbody > tr > td.weekend-cell:hover {
        cursor: pointer;
        border-bottom: 2px solid #8f8f8f;
    }
`;

const Tempo = () => {
    const [users, setUsers] = useState([]);
    const [builds, setBuilds] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = useState();
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [dateArray, setDateArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [dataTrigger, setDataTrigger] = useState(false);
    const [dates, setDates] = useState([null, null]);

    const handleFilter = (selectedValues) => {
        console.log('selectedValues', selectedValues);

        const newFilters = {
            userIds: [],
            buildIds: [],
            taskKeys: {}
        };

        selectedValues.forEach(([type, field, value]) => {
            if (type === "users") {
                newFilters.userIds.push(field);
            } else if (type === "builds") {
                newFilters.buildIds.push(field);
            } else if (type === "tasks") {
                if (!newFilters.taskKeys[field]) {
                    newFilters.taskKeys[field] = [];
                }
                newFilters.taskKeys[field].push(value);
            }
        });

        console.log('newFilters', newFilters);
        setFilters(newFilters);
    };


    const handlePrevPeriod = () => {
        let newStartDate, newEndDate;
        if (!startDate || !endDate) {
            newStartDate = dayjs().startOf('month');
            newEndDate = dayjs().endOf('month');
        } else {
            newStartDate = dayjs(startDate).subtract(1, 'month').startOf('month');
            newEndDate = dayjs(endDate).subtract(1, 'month').endOf('month');
        }
        setStartDate(newStartDate.toDate());
        setEndDate(newEndDate.toDate());
    };

    const handleNextPeriod = () => {
        let newStartDate, newEndDate;
        if (!startDate || !endDate) {
            newStartDate = dayjs().startOf('month');
            newEndDate = dayjs().endOf('month');
        } else {
            newStartDate = dayjs(startDate).add(1, 'month').startOf('month');
            newEndDate = dayjs(endDate).add(1, 'month').endOf('month');
        }
        setStartDate(newStartDate.toDate());
        setEndDate(newEndDate.toDate());
    };

    const transformData = (data) => {
        const result = [];

        data.forEach(item => {
            item.tasks.forEach(task => {
                task.worklogs.forEach(log => {
                    result.push({
                        Build: item.sow_name,
                        Task: task.summary,
                        Date: log.created_at.split('T')[0],
                        Time: log.time_spent_hours,
                        Log: log.comment
                    });
                });
            });
        });

        return result;
    };

    const transformAccountData = (data) => {
        const result = [];

        data.forEach(account => {
            account.builds.forEach(build => {
                build.children.forEach(task => {
                    task.worklogs.forEach(log => {
                        result.push({
                            Account: account.name,
                            Task: task.summary,
                            Date: log.created_at.split('T')[0],
                            Time: log.time_spent_hours,
                            Log: log.comment
                        });
                    });
                });
            });
        });

        return result;
    };

    const transformUserData = (data) => {
        const result = [];

        data.forEach(user => {
            user.worklogs.forEach(log => {
                result.push({
                    User: user.display_name.trim(),
                    Task: log.task.summary,
                    Date: log.created_at.split('T')[0],
                    Time: log.time_spent_hours,
                    Log: log.comment
                });
            });
        });

        return result;
    };

    const escapeCSVValue = (value) => {
        if (value == null) return '';
        const escapedValue = value.toString().replace(/"/g, '""');
        return `"${escapedValue}"`;
    };

    const convertToCSV = (data, headers) => {
        const rows = data.map(item =>
            headers.map(header => escapeCSVValue(item[header])).join(',')
        );

        return [headers.map(escapeCSVValue).join(',')].concat(rows).join('\n');
    };

    const downloadCSV = (csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleExportCSV = () => {
        let transformedData;
        let headers;

        switch (selectedGroupType.value) {
            case 'build':
                transformedData = transformData(builds);
                headers = ['Build', 'Task', 'Date', 'Time', 'Log'];
                break;
            case 'user':
                transformedData = transformUserData(users);
                headers = ['User', 'Task', 'Date', 'Time', 'Log'];
                break;
            case 'account':
                transformedData = transformAccountData(accounts);
                headers = ['Account', 'Task', 'Date', 'Time', 'Log'];
                break;
            default:
                console.error('Invalid group type selected');
                return;
        }

        const csvContent = convertToCSV(transformedData, headers);
        downloadCSV(csvContent);
    };

    const fetchLogsForDate = async (date) => {
        try {
            const logs = await ServiceWorklogs.getWorklogsForDate(date);
            setModalLoading(false);
            return logs.data;
        } catch (error) {
            console.error('Error fetching logs:', error);
            return [];
        }
    };

    const formatDateForApi = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const showModal = async (content) => {
        setLoading(true);
        setModalLoading(true);
        setIsModalVisible(true);
        if (content && content.date) {
            try {
                const formattedDate = formatDateForApi(dayjs(content.date, 'DD MMM YYYY'));
                const logs = await fetchLogsForDate(formattedDate);
                setModalContent({
                    ...content,
                    logs
                });
                setLoading(false);
            } catch (error) {
                console.error('Error handling modal content:', error);
            }
        } else {
            console.error('Modal content is missing or not properly initialized:', content);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setModalLoading(false);
        setModalContent(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalLoading(false);
        setModalContent(null);
    };

    const handleDataChange = (data) => {
        setDataTrigger(data);
        console.log('Data updated.');
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [userResponse, buildResponse, accountResponse] = await Promise.all([
                ServiceUsers.getAllUsers(filters),
                ServiceBuilds.getBuilds(filters),
                ServiceBuilds.getAccountsWithBuilds()
            ]);

            // const userData = await Promise.all(userResponse.data.map(async (item) => {
            //     const user = {
            //         id: item.id,
            //         email: item.email,
            //         display_name: item.display_name,
            //         active: item.active,
            //         builds: item.builds,
            //         worklogs: []
            //     };

            //     if (item.worklogs && item.worklogs.length > 0) {
            //         const worklogPromises = item.worklogs.map(async (worklog) => {
            //             const response = await ServiceTasks.getTask(worklog.task_key);
            //             worklog.task = response?.data?.task || null;
            //             return worklog;
            //         });

            //         user.worklogs = await Promise.all(worklogPromises);
            //     }

            //     return user;
            // }));

            const userData = userResponse.data;
            const buildData = buildResponse.data;
            const accountData = accountResponse.data;

            console.log('accountData', accountData);
            console.log('buildData', buildData);
            console.log('userData', userData);

            setUsers(userData);
            setBuilds(buildData);
            setAccounts(accountData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAndSetData = async () => {
        await fetchData();
        if (startDate && endDate) {
            const selectedGroupValues = selectedGroup && selectedGroup.map(group => group.value);
            displayGroupedData(startDate, endDate, selectedGroupType.value, selectedGroupValues);
        }
    };

    useEffect(() => {
        document.title = 'Tempo';
        if (filters) {
            fetchAndSetData();
        }
    }, [selectedGroupType, selectedGroup, startDate, endDate, selectedFilters, dataTrigger, filters]);

    useEffect(() => {
        if (users.length > 0 || builds.length > 0) {
            if (selectedGroupType?.value) {
                const selectedGroupValues = selectedGroup.map(group => group?.value).filter(Boolean);
                displayGroupedData(startDate, endDate, selectedGroupType.value, selectedGroupValues);
            }
        }
    }, [users, builds, accounts]);


    const displayGroupedData = (startDate, endDate, groupType, groupByArray) => {
        console.log('groupByArray', groupByArray)
        const start = moment(startDate);
        const end = moment(endDate);

        const newDateArray = [];
        for (let date = start.clone(); date <= end; date.add(1, 'day')) {
            newDateArray.push(date.format('DD MMM YYYY ddd'));
        }
        setDateArray(newDateArray);
        const filteredData = [];

        if (groupType === 'user') {
            const uniqueGroupKeys = new Set();
            users.forEach(user => {
                const timeByDate = {};
                newDateArray.forEach(date => {
                    timeByDate[date] = 0;
                });
                user.worklogs.forEach(worklog => {
                    const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                    if (timeByDate[logDate] !== undefined) {
                        timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                    }
                });

                const userEntry = {
                    type: 'user',
                    name: <div style={{ fontWeight: '700' }}>{user.display_name}</div>,
                    data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
                    total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>
                };
                filteredData.push(userEntry);
                uniqueGroupKeys.add(user.id);
                const firstItem = groupByArray[0];

                if (groupByArray && groupByArray.length > 0 && ['summary', 'status', 'priority', 'billing'].includes(firstItem)) {
                const groupedItems = {};

                user.worklogs.forEach(worklog => {
                    const groupKey = groupByArray.map(groupBy =>
                        groupBy === 'summary' ? worklog.task.summary :
                            groupBy === 'status' ? worklog.task.status :
                                groupBy === 'priority' ? worklog.task.priority :
                                    worklog.task.billing
                    ).join(' / ');

                    if (!groupedItems[groupKey]) {
                        groupedItems[groupKey] = {
                            key: groupKey,
                            worklogs: []
                        };
                    }
                    groupedItems[groupKey].worklogs.push(worklog);
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });
                });
            } else if (groupByArray.some(item => ['sow_name'].includes(item))) {
                const groupedItems = {};

                user.builds && user.builds.forEach(build => {

                    const groupKey = build.id;
                    const sow_name = build.sow_name;

                    if (!groupedItems[groupKey]) {
                        groupedItems[groupKey] = {
                            key: groupKey,
                            sow_name: sow_name,
                            worklogs: [],
                            tasks: []
                        };

                    }
                    groupedItems[groupKey].worklogs = build.worklogs;
                    if (build.tasks) {
                        groupedItems[groupKey].tasks = build.tasks;
                    }
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs && item.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.sow_name}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });



                    if (groupByArray.length > 1) {

                        // Build -> Tasks
                        const groupedItems = {};
                        console.log('task');

                        item.tasks && item.tasks.forEach(task => {

                            const groupKey =
                                (groupByArray[1] === 'summary' ? task.summary :
                                    groupByArray[1] === 'status' ? task.status :
                                        groupByArray[1] === 'priority' ? task.priority :
                                            task.billing);

                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    worklogs: [],
                                };

                            }
                            groupedItems[groupKey].worklogs = task.worklogs;
                        });

                        Object.values(groupedItems).forEach(item => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });

                            filteredData.push({
                                type: 'grouped',
                                name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                            });
                        });
                    }
                });
            }
        });
        setTableData(filteredData);
    }
        else if (groupType === 'build') {
    const uniqueGroupKeys = new Set();

    builds.forEach((build) => {
        const timeByDate = {};
        newDateArray.forEach(date => {
            timeByDate[date] = 0;
        });

        if (build.tasks) {
            build.tasks.forEach(task => {
                if (task.worklogs) {
                    task.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });
                }
            })
        }

        const buildEntry = {
            type: 'build',
            name: groupType === 'build_status' ?
                <div style={{ fontWeight: '700' }}>
                    <Tag color="processing">{build.status}</Tag>
                </div> :
                groupType === 'build_account' ?
                    <div style={{ fontWeight: '700' }}>{build.account_name}</div> :
                    <div style={{ fontWeight: '700' }}>{build.sow_name}</div>,
            data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
            total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>
        };

        filteredData.push(buildEntry);
        uniqueGroupKeys.add(build.id);

        if (groupByArray && groupByArray.length > 0) {

            // User

            const groupedItems = [];

            if (groupByArray[0] === 'user') {
                build.users.forEach(user => {
                    const groupKey = user.user_id;
                    const name = user.name;

                    if (!groupedItems[groupKey]) {
                        groupedItems[groupKey] = {
                            key: groupKey,
                            user_name: name,
                            worklogs: [],
                            tasks: []
                        };
                    }

                    user.worklogs.forEach(worklog => {
                        groupedItems[groupKey].worklogs.push(worklog);
                    });

                    user.tasks.forEach(task => {
                        const taskWorklogs = task.worklogs.filter(log => log.user_id === user.user_id);

                        if (taskWorklogs.length > 0) {
                            groupedItems[groupKey].tasks.push({
                                ...task,
                                worklogs: taskWorklogs
                            });
                        }
                    });
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.user_name}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });

                    if (groupByArray.length > 1 && (groupByArray[1] === 'summary' || groupByArray[1] === 'status' || groupByArray[1] === 'priority' || groupByArray[1] === 'billing')) {
                        const groupedItems = {};

                        item.tasks.forEach(task => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            const groupKey =
                                groupByArray[1] === 'summary' ? task.summary :
                                    groupByArray[1] === 'status' ? task.status :
                                        groupByArray[1] === 'priority' ? task.priority : task.billing;

                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    worklogs: []
                                };
                            }

                            groupedItems[groupKey].worklogs.push(...task.worklogs);
                        });

                        Object.values(groupedItems).forEach(item => {
                            const timeByDate = {};
                            newDateArray.forEach(date => {
                                timeByDate[date] = 0;
                            });

                            item.worklogs.forEach(worklog => {
                                const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                if (timeByDate[logDate] !== undefined) {
                                    timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                }
                            });

                            filteredData.push({
                                type: 'grouped',
                                name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                                data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                            });
                        });
                    }
                })
            }
            else {

                // Task

                const groupedItems = {};
                build.tasks.forEach(task => {
                    const groupKey =
                        groupByArray[0] === 'summary' ? task.summary :
                            groupByArray[0] === 'status' ? task.status :
                                groupByArray[0] === 'priority' ? task.priority : task.billing;

                    if (!groupedItems[groupKey]) {
                        groupedItems[groupKey] = {
                            key: groupKey,
                            worklogs: []
                        };
                    }
                    groupedItems[groupKey].worklogs.push(task.worklogs);
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs.forEach(worklog => {
                        worklog.forEach(worklog => {
                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                            if (timeByDate[logDate] !== undefined) {
                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                            }
                        })
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });
                });
            }
        }
        setTableData(filteredData);
    }
    )
}
else {
    const uniqueGroupKeys = new Set();

    accounts.forEach((account) => {
        const timeByDate = {};
        newDateArray.forEach(date => {
            timeByDate[date] = 0;
        });

        if (account.builds) {
            account.builds && account.builds.forEach(build => {
                if (build.worklogs) {
                    build.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });
                }
            })
        }

        const accountEntry = {
            type: 'account',
            name:
                <div style={{ fontWeight: '700' }}>
                    {account.account_name}
                </div>,
            data: newDateArray.map(date => timeByDate[date] ? <span style={{ fontWeight: '700' }}>{timeByDate[date].toFixed(2)}</span> : ''),
            total: <span style={{ fontWeight: '700' }}>{(Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')}</span>
        };

        filteredData.push(accountEntry);
        uniqueGroupKeys.add(account.id);

        if (groupByArray[0]) {

            // Build

            if (groupByArray[0] === 'sow_name' || groupByArray[0] === 'administrator') {
                const groupedItems = {};
                account.builds.forEach(build => {
                    const groupKey =
                        groupByArray[0] === 'sow_name' ? build.name :
                            groupByArray[0] === 'administrator' ? build.administrator_name : build.administrator_name;
                    if (!groupedItems[groupKey]) {
                        groupedItems[groupKey] = {
                            key: groupKey,
                            worklogs: []
                        };
                    }
                    groupedItems[groupKey].worklogs.push(build.worklogs);
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs.forEach(worklog => {
                        worklog.forEach(worklog => {
                            const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                            if (timeByDate[logDate] !== undefined) {
                                timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                            }
                        })
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });

                    const groupedItems = {};

                    // Users

                    if (groupByArray.length > 1) {

                        if (groupByArray[1] === 'user') {
                            account.builds.forEach(build => {
                                build.users && build.users.forEach(user => {
                                    const groupKey = user.user_name;
                                    if (!groupedItems[groupKey]) {
                                        groupedItems[groupKey] = {
                                            key: groupKey,
                                            worklogs: []
                                        };
                                    }
                                    groupedItems[groupKey].worklogs.push(user.worklogs);
                                })

                            });

                            Object.values(groupedItems).forEach(item => {
                                const timeByDate = {};
                                newDateArray.forEach(date => {
                                    timeByDate[date] = 0;
                                });

                                item.worklogs.forEach(worklog => {
                                    worklog.forEach(worklog => {
                                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                                        if (timeByDate[logDate] !== undefined) {
                                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                                        }
                                    })
                                });

                                filteredData.push({
                                    type: 'grouped',
                                    name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                                    data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                                    total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                                });
                            })
                        }
                    }
                });
            } else if (groupByArray[0] !== 'user') {

                // Task

                const groupedItems = {};
                account.builds && account.builds.forEach(build => {
                    if (build.tasks) {
                        build.tasks.forEach(task => {
                            const groupKey =
                                groupByArray[0] === 'summary' ? task.summary :
                                    groupByArray[0] === 'status' ? task.status :
                                        groupByArray[0] === 'priority' ? task.priority : task.billing;

                            if (!groupedItems[groupKey]) {
                                groupedItems[groupKey] = {
                                    key: groupKey,
                                    worklogs: []
                                };
                            }

                            if (task.worklogs) {
                                task.worklogs.forEach(worklog => {
                                    groupedItems[groupKey].worklogs.push(worklog);
                                });
                            }
                        });
                    }
                });

                Object.values(groupedItems).forEach(item => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    item.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{item.key}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });
                });
            } else {

                const groupedByUser = {};

                account.builds && account.builds.forEach(build => {
                    if (build.children) {
                        build.children.forEach(task => {
                            if (task.worklogs) {
                                task.worklogs.forEach(worklog => {
                                    const userId = worklog.user_id;
                                    if (!groupedByUser[userId]) {
                                        groupedByUser[userId] = {
                                            userId: userId,
                                            worklogs: [],
                                        };
                                    }
                                    groupedByUser[userId].worklogs.push(worklog);
                                });
                            }
                        });
                    }
                });

                Object.values(groupedByUser).forEach(user => {
                    const timeByDate = {};
                    newDateArray.forEach(date => {
                        timeByDate[date] = 0;
                    });

                    user.worklogs.forEach(worklog => {
                        const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                        if (timeByDate[logDate] !== undefined) {
                            timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                        }
                    });

                    filteredData.push({
                        type: 'grouped',
                        name: <span style={{ paddingLeft: '16px' }}>{user.userId}</span>,
                        data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                        total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    });


                    // Tasks

                    // const groupedItems = {};

                    // user.tasks && user.tasks.forEach(task => {
                    //     const timeByDate = {};
                    //     newDateArray.forEach(date => {
                    //         timeByDate[date] = 0;
                    //     });

                    //     const groupKey =
                    //         groupByArray[1] === 'summary' ? task.summary :
                    //             groupByArray[1] === 'status' ? task.status :
                    //                 groupByArray[1] === 'priority' ? task.priority : task.billing;

                    //     if (!groupedItems[groupKey]) {
                    //         groupedItems[groupKey] = {
                    //             key: groupKey,
                    //             worklogs: []
                    //         };
                    //     }

                    //     groupedItems[groupKey].worklogs.push(...task.worklogs);
                    // });

                    // Object.values(groupedItems).forEach(item => {
                    //     const timeByDate = {};
                    //     newDateArray.forEach(date => {
                    //         timeByDate[date] = 0;
                    //     });

                    //     item.worklogs.forEach(worklog => {
                    //         const logDate = moment(worklog.created_at).format('DD MMM YYYY ddd');
                    //         if (timeByDate[logDate] !== undefined) {
                    //             timeByDate[logDate] += parseFloat(worklog.time_spent_hours);
                    //         }
                    //     });

                    //     filteredData.push({
                    //         type: 'grouped',
                    //         name: <span style={{ paddingLeft: '32px' }}>{item.key}</span>,
                    //         data: newDateArray.map(date => timeByDate[date] ? timeByDate[date].toFixed(2) : ''),
                    //         total: (Object.values(timeByDate).reduce((a, b) => a + b, 0) || 0).toFixed(2).replace(/^0\.00$/, '')
                    //     });
                    // });

                });
            }
        }
        setTableData(filteredData);
    })
}
    }

const getColumnTitle = () => {

    if (!selectedGroupType) {
        return 'No Grouping Selected';
    }

    const groupLabels = Array.isArray(selectedGroup)
        ? selectedGroup.map(group => group.label).join(' / ')
        : selectedGroup?.label || '';

    return `${selectedGroupType.label}${groupLabels ? ' / ' + groupLabels : ''}`;
};


// const handleTableChange = useCallback(async (pagination, filters, sorter, userData) => {

//     try {
//         setLoading(true);
//         if (!sorter.column) {
//             let filteredUserData;
//             if (filters.name && filters.name.length > 0) {
//                 if (selectedGroupType.value === 'user') {
//                     filteredUserData = await ServiceUsers.applyFilter(
//                         selectedGroup[0].value,
//                         filters.name
//                     );
//                     setUsers(filteredUserData.data);
//                 }

//                 if (selectedGroupType.value === 'build') {
//                     filteredUserData = await ServiceBuilds.applyFilter(
//                         selectedGroup[0].value,
//                         filters.name
//                     );
//                     setBuilds(filteredUserData.data);
//                 }
//             }
//             setSelectedFilters(filters);
//         } else {
//             if (selectedGroupType.value === 'user') {
//                 let sortedUserData = await ServiceUsers.applySorter(sorter.order);
//                 setUsers(sortedUserData.data);
//             }

//             if (selectedGroupType.value === 'build') {
//                 let sortedBuildData = await ServiceBuilds.applySorter(sorter.order);
//                 setBuilds(sortedBuildData.data);
//             }
//         }
//         setLoading(false);
//     } catch (error) {
//         console.error('Failed to fetch data:', error);
//     }
// }, [selectedGroupType, selectedGroup]);

const columns = useMemo(() => {
    // const dynamicFilterOptions = filters ? filters : [];

    return [
        {
            title: <span style={{ color: '#0047AB' }}>{getColumnTitle()}</span>,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 275,
            align: 'left',
            showSorterTooltip: {
                target: 'full-header',
            },
            // filters: dynamicFilterOptions,
            filterSearch: true,
            // sorter: true,
        },
        ...dateArray.map((date, idx) => {

            const [day, month, year, dayOfWeek] = date.split(' ');
            const dateMoment = dayjs(date, 'DD MMM YYYY ddd');
            const isWeekend = dateMoment.day() === 6 || dateMoment.day() === 0;
            const isToday = dateMoment.isSame(dayjs(), 'day');

            let className = 'basic-cell';
            if (isWeekend) {
                className = 'weekend-cell';
            }
            if (isToday) {
                className = `${className} weekend-today-bordered`.trim();
            }

            return {
                title: (
                    <div key={`date-${idx}`}>
                        {day === '01' ? <span style={{ color: 'red' }}>{month}</span> : <div style={{ color: day === '01' ? 'red' : 'black' }}>{day}</div>}
                        <div style={{ fontSize: '0.8em', color: '#888' }}>{dayOfWeek}</div>
                    </div>
                ),
                className: className,
                dataIndex: `date-${idx}`,
                key: `date-${idx}`,
                width: 40,
                align: 'center',
                showSorterTooltip: {
                    target: 'full-header',
                },
                render: (text, record) => {
                    const dataForThisDay = record.data && record.data[idx] ? record.data[idx] : null;
                    const dateForThisDay = `${day} ${month} ${year}`;
                    return (
                        <div style={{ width: '100%', height: '100%' }} onClick={() => showModal({ data: dataForThisDay, date: dateForThisDay })}>
                            {record.data[idx]}
                        </div>
                    );
                },
            };
        }),
        {
            title: <span style={{ color: '#0047AB' }}>Total</span>,
            dataIndex: 'total',
            key: 'total',
            fixed: 'right',
            width: 100,
            align: 'center',
            showSorterTooltip: {
                target: 'full-header',
            },
            render: (text) => <span>{text}</span>,
        },
    ];
}, [dateArray, getColumnTitle, users]);


const handleGroupChange = (selectedOption, option) => {
    setSelectedGroupType({ value: selectedOption, label: option.label });
    // setSelectedFilters([]);
    setSelectedGroup([]);
};

const handleGroupByChange = (value, option) => {
    console.log('option', option);

    setSelectedGroup(value.map((val, index) => ({ value: val, label: option[index].label })));
};

const options = selectedGroupType && selectedGroupType.value === 'user' ? [
    { value: 'summary', label: 'Summary', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
    { value: 'status', label: 'Status', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
    { value: 'priority', label: 'Priority', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
    { value: 'billing', label: 'Billing', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
    { value: 'sow_name', label: 'SoW', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Build</b> <span>SoW</span></div> }
] : selectedGroupType && selectedGroupType.value === 'build' ? [
    { value: 'summary', label: 'Summary', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
    { value: 'status', label: 'Status', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
    { value: 'priority', label: 'Priority', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
    { value: 'billing', label: 'Billing', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
    { value: 'user', label: 'User', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>User</b> <span>Name</span></div> }
] : selectedGroupType && selectedGroupType.value === 'account' ? [
    { value: 'summary', label: 'Summary', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Summary</span></div> },
    { value: 'status', label: 'Status', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Status</span></div> },
    { value: 'priority', label: 'Priority', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Priority</span></div> },
    { value: 'billing', label: 'Billing', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Task</b> <span>Billing</span></div> },
    { value: 'sow_name', label: 'SoW', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>Build</b> <span>SoW</span></div> },
    { value: 'user', label: 'User', desc: <div><b style={{ color: '#3d91d6', paddingRight: '4px' }}>User</b> <span>Name</span></div> }
] : [];


const filteredOptions = options.filter((o) => {
    const selectedValue = selectedGroup[0]?.value;
    if (selectedValue) {

        if (selectedGroupType.value === 'user') {
            if (selectedValue === 'sow_name') {
                return o.value === selectedValue || ['summary', 'priority', 'billing', 'status'].includes(o.value);
            }

            return o.value === selectedValue;
        }

        if (selectedGroupType.value === 'build') {
            if (selectedValue === 'user') {
                return o.value === selectedValue || ['summary', 'priority', 'billing', 'status'].includes(o.value);
            }

            return o.value === selectedValue;
        }

        if (selectedGroupType.value === 'account') {
            if (selectedValue === 'sow_name') {
                return o.value === selectedValue || o.value === 'user';
            }

            if (selectedValue !== 'sow_name') {
                return o.value === selectedValue;
            }
        }
    }

    return true;
});

return (
    <ConfigProvider
        theme={{
            components: {
                Table: {
                    borderColor: '#cbcbcb',
                    headerBorderRadius: 0,
                    cellFontSize: 12,
                    cellFontSizeMD: 12,
                    cellFontSizeSM: 12
                },
            },
        }}
    >
        <div className='tempo'>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>
                <CalendarTwoTone twoToneColor="blue" style={{ marginRight: '8px' }} />
                Tempo Timesheet
            </h1>
            <div className='control-section'>
                <div style={{ marginRight: '16px' }}>
                    <div className='calendar-switcher'>
                        <div>
                            <Button onClick={handlePrevPeriod}>&lt;</Button>
                        </div>
                        <div>
                            <RangePicker
                                presets={rangePresets}
                                value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                                style={{
                                    width: 250,
                                }}
                                onChange={(dates) => {
                                    setStartDate(dates ? dayjs(dates[0]).toDate() : null);
                                    setEndDate(dates ? dayjs(dates[1]).toDate() : null);
                                }}
                            />
                        </div>
                        <div>
                            <Button onClick={handleNextPeriod}>&gt;</Button>
                        </div>
                    </div>
                </div>
                <FilterComponent onFilter={handleFilter} />
                <div style={{ marginRight: '16px' }}>
                    <Select
                        showSearch
                        className='select-group'
                        style={{
                            width: 200,
                        }}
                        size='medium'
                        onChange={handleGroupChange}
                        placeholder="Inital Grouping"
                        options={[
                            {
                                label: <span>User</span>,
                                title: 'User',
                                options: [
                                    { value: 'user', label: 'User' },
                                ]
                            },
                            {
                                label: <span>Build</span>,
                                title: 'Build',
                                options: [
                                    { value: 'build', label: 'Build' },
                                ]
                            },
                            {
                                label: <span>Account</span>,
                                title: 'Account',
                                options: [
                                    { value: 'account', label: 'Account' },
                                ]
                            },
                        ]}
                    />
                </div>
                {selectedGroupType && (
                    <div style={{ marginRight: '16px' }}>
                        <Select
                            maxCount={2}
                            mode="multiple"
                            placeholder="Additional Grouping"
                            style={{
                                width: '200px',
                            }}
                            value={selectedGroup.map(item => item.value)}
                            onChange={handleGroupByChange}
                            options={filteredOptions}
                            optionRender={(option) => (
                                <Space>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </div>
                )}
                <div>
                    <Button type="dashed" disabled>
                        Clear Filters
                    </Button>

                </div>
                <div>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={handleExportCSV}>
                        CSV
                    </Button>
                </div>
            </div>
            <div className='hint'>
                <span>Tempo v.1.0.0</span>
            </div>

            <StyledTable
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 'max-content', y: '55vh' }}
                bordered
                size='small'
                loading={loading}
                // onChange={handleTableChange}
                summary={() => (
                    <Table.Summary fixed>

                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}><span style={{ fontWeight: '700' }}>Total</span></Table.Summary.Cell>
                            {dateArray.map((_, idx) => {
                                const sum = tableData.reduce((acc, row) => {
                                    return acc + (parseFloat(row.data[idx].props ? row.data[idx].props.children : '') || 0);
                                }, 0);

                                return (
                                    <Table.Summary.Cell key={`summary-date-${idx}`} index={idx + 1} align='center'>
                                        <span style={{ fontWeight: '700' }}>{sum > 0 ? sum.toFixed(2) : ''}</span>
                                    </Table.Summary.Cell>
                                );
                            })}
                            <Table.Summary.Cell key="summary-total" index={dateArray.length + 1} align='center'>
                                <span style={{ fontWeight: '700' }}>
                                    {tableData.reduce((acc, row) => acc + (parseFloat(row.total.props ? row.total.props.children : 0) || 0), 0) > 0 ?
                                        tableData.reduce((acc, row) => acc + (parseFloat(row.total.props ? row.total.props.children : 0) || 0), 0).toFixed(2) : ''}
                                </span>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )
                }
            />
            <TempoModal
                isVisible={isModalVisible}
                content={modalContent}
                handleOk={handleOk}
                handleCancel={handleCancel}
                loading={modalLoading}
                onDataChange={handleDataChange}
            />
        </div >
    </ConfigProvider>

);
};

export default Tempo;